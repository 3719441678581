import * as React from "react";
import {Helmet} from "react-helmet";
import {Route, Link, Switch} from "react-router-dom";
import {Navigation} from "../common/navigation";

const googlePlay = require("../../../src/assets/google-play-btn.svg");
const appStore = require("../../../src/assets/app-store-btn.svg");

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class HeaderBtrn extends React.Component<{}, {}> {
    render() {
        return (
            <>
                <Helmet>
                    <title>Blockchain &amp; BTRN token</title>
                </Helmet>
                <Navigation selected="btrn"/>
                <header className="biotron-token">
                    <div className="bg-token">

                        <div className="wrapper main-title">
                        <h1 className="title bold white left">
                            Blockchain &amp; Token
                        </h1>
                            <span className="btrn semi-bold left">BTRN</span>
                        </div>
                    </div>
                </header>
                <span className="clear"></span>
            </>
        );
    }
}
