import * as React from "react";
import NumberFormat from "react-number-format";
import { HeaderBtrn } from "../components/btrn/HeaderBtrn";
import { LetsTalk } from "../components/common/letsTalk";
import { ModalForm } from "../components/common/modalForm";

const infoI = require("../../src/assets/info-i.svg");
const fingerprint = require("../../src/assets/fingerprint.png");
const arrowSmallRightBlue = require("../../src/assets/arrow-small-right-blue.svg");
const liquid = require("../../src/assets/liquid.png");
const idex = require("../../src/assets/idex.png");

export interface ContentProps {
}

export interface BtrnState {
    isModalActive: boolean;
    btrnStatus: BtrnStatus;
}

export interface BtrnStatus {
    price: string;
    supply: string;
    marketCap: string;
    volume24h: string;
}

export class Btrn extends React.Component<ContentProps, BtrnState> {

    constructor(props: ContentProps) {
        super(props);

        window.scrollTo(0, 0);

        this.state = {
            isModalActive: false,
            btrnStatus: {
                price: "loading",
                supply: "loading",
                marketCap: "loading",
                volume24h: "loading"
            }
        };
    }

    componentDidMount() {
        this.getBtrnStatus();
    }

    render(): JSX.Element {

        const { btrnStatus } = this.state;

        return (
            <>
                <HeaderBtrn />
                <div className="content">
                    <div className="wrapper exchange bg-white">

                        <div className="block-right btrn-marketcap">
                            {/* <div className="row first-row">
                                <img className="fingerprint" src={fingerprint} />
                                <div className="left value">
                                    <span className="cap-left left">Biotron Value</span>
                                    <span className="price left semi-bold">&euro; <NumberFormat value={btrnStatus.price} decimalScale={3} displayType={"text"} thousandSeparator={true} /> </span>
                                </div>
                                <a href="https://coinmarketcap.com/currencies/biotron/" target="_blank">
                                    <span className="button button-light-blue right">
                                        Market info
                                   <img src={arrowSmallRightBlue} /></span>
                                </a>
                            </div> */}
                            {/* <hr className="hr-line under-first" />
                            <div className="row">
                                <span className="cap-left left">Circulating supply</span>
                                <span className="cap-right right"><NumberFormat value={btrnStatus.supply} decimalScale={2} displayType={"text"} thousandSeparator={true} />  BTRN</span>
                            </div>
                            <hr className="hr-line" />
                            <div className="row">
                                <span className="cap-left left">Volume (24h)</span>
                                <span className="cap-right right">&euro; <NumberFormat value={btrnStatus.volume24h} decimalScale={0} displayType={"text"} thousandSeparator={true} /></span>
                            </div>
                            <hr className="hr-line" />
                            <div className="row row-last">
                                <span className="cap-left left">Market cap</span>
                                <span className="cap-right right">&euro; <NumberFormat value={btrnStatus.marketCap} decimalScale={0} displayType={"text"} thousandSeparator={true} /> </span>
                            </div>
                            <hr className="hr-line-blue" /> */}
                            <div className="row exchanges">
                                <div className="row title-semi center exchange-title">
                                    BTRN on Exchanges
                                </div>
                                <div className="centeredRow">
                                    <a href="https://app.liquid.com/exchange" target="_blank">
                                        <img className="center liquid" src={liquid} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="block-left">
                            <div className="row">
                                <p className="description">
                                    Biotron utilizes blockchain for two main tasks — to facilitate transactions of data,
                                    and to host smart contracts between data providers and data customers.
                                </p>
                                <p className="description">
                                    Biotron Token (BTRN) is a utility token playing an integral part in our solution.
                                    It enables exchange of data between organisations and data providers.
                                </p>
                            </div>
                            <div className="row">
                                <a href="https://medium.com/biotron/how-to-add-btrn-custom-token-on-myetherwallet-b2af6f8a7e9a" target="_blank">
                                    <span className="info left"><img className="info-icon" src={infoI} />How to access BTRN token</span>
                                </a>
                                <a href="https://etherscan.io/token/0x03c780cd554598592b97b7256ddaad759945b125" target="_blank">
                                    <span className="info left"><img className="info-icon" src={infoI} />Token smart contract info</span>
                                </a>
                            </div>
                        </div>
                        <div className="block-right btrn-marketcap-mobile">
                            <hr className="hr-line-blue" />
                            <div className="row exchanges">
                                <div className="flex-it">
                                    <div className="row title-semi center exchange-title">
                                        BTRN on Exchanges
                                    </div>
                                    <a href="https://app.liquid.com/exchange" target="_blank">
                                        <img className="left" src={liquid} />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <LetsTalk setModalState={this.setModalState} isModalActive={this.state.isModalActive} />
                <ModalForm isActive={this.state.isModalActive} setModalState={this.setModalState} />
            </>
        );
    }

    private setModalState = (modalState: boolean) => {

        let isActive = !this.state.isModalActive;
        if (!modalState) {
            isActive = false;
        }

        this.setState({ isModalActive: isActive });
    }

    private getBtrnStatus = () => {

        fetch("https://api.coinmarketcap.com/v2/ticker/2690/?convert=EUR",
            {
                method: "GET",
                mode: "cors",
                redirect: "follow", // *manual, error
                referrer: "no-referrer", // *client
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *omit
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": "true"
                },
            })
            .then(response => {
                // if (response.status >= 400) {
                //     this.setState({icoStatusState: IcoStatusState.ERROR});
                // } else {
                return response.json();
                // }
            })
            .then(data => {
                this.setState({
                    btrnStatus: {
                        supply: data.data.circulating_supply,
                        price: data.data.quotes.EUR.price,
                        marketCap: data.data.quotes.EUR.market_cap,
                        volume24h: data.data.quotes.EUR.volume_24h
                    },
                });
            })
            .catch(error => {
                // this.setState({icoStatusState: IcoStatusState.ERROR});
            });
    }
}
