import * as React from "react";
import Slider from "react-slick";

const mi = require("../../../src/assets/media/featured-in/mi.jpg");
const thenextweb = require("../../../src/assets/media/featured-in/thenextweb.png");
const forbes = require("../../../src/assets/media/featured-in/forbes.png");
const sociable = require("../../../src/assets/media/featured-in/sociable-logo.png");
// const newsbtc = require("../../../src/assets/media/featured-in/newsbtc.png");
// const bitcoingarden = require("../../../src/assets/media/featured-in/bitcoingarden-png-1.png");
// const ccm = require("../../../src/assets/media/featured-in/ccm-logo_625.png");
// const cointurk = require("../../../src/assets/media/featured-in/cointurk_logo.svg");

const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true
};

export class FeaturedIn extends React.Component<{}, {}> {

    render(): JSX.Element {
        return (
            <>
                <div className="wrapper featured-in bg-white">
                    <h3 className="title-semi-bold text-center">We've been featured in</h3>
                    <div className="row">
                    <Slider {...settings}>
                        <div>
                            {/* <a href="https://markets.businessinsider.com/" target="_blank"> */}
                                <img className="mi" src={mi}/>
                            {/* </a> */}
                        </div>
                        <div>
                            {/* <a href="https://thenextweb.com/contributors/2018/08/11/heres-why-youre-being-kept-in-the-dark-about-how-your-data-is-used/" target="_blank"> */}
                                <img className="thenextweb" src={thenextweb}/>
                            {/* </a> */}
                        </div>
                        <div>
                            {/* <a href="https://www.forbes.sk/toto-su-slovenske-top-startupy-roka-kto-nas-bude-reprezentovat-v-zahranici/#" target="_blank"> */}
                                <img className="forbes" src={forbes}/>
                            {/* </a> */}
                        </div>
                        <div>
                            {/* <a href="https://sociable.co/technology/smart-cities-goldmine-for-data/" target="_blank"> */}
                                <img className="sociable" src={sociable}/>
                            {/* </a> */}
                        </div>
                        {/* <div>
                            <a href="https://www.newsbtc.com/press-releases/biotron-data-app-downloads-cross-20000/" target="_blank">
                                <img className="newsbtc" src={newsbtc}/>
                            </a>
                        </div>
                        <div>
                            <a href="https://bitcoingarden.org/biotron-data-an-app-by-the-biotron-foundation-lets-users-earn-btrn-tokens-by-sharing-their-data/"
                               target="_blank">
                                <img className="bitcoingarden" src={bitcoingarden}/>
                            </a>
                        </div>
                        <div>
                            <a href="http://cryptocurrencymagazine.com" target="_blank">
                                <img className="ccmagazine" src={ccm}/>
                            </a>
                        </div> */}
                    </Slider>
                    </div>
                </div>
            </>
        );
    }
}
