import * as React from "react";
import {Helmet} from "react-helmet";
import {Route, Link, Switch} from "react-router-dom";
import {Navigation} from "../common/navigation";

const googlePlay = require("../../../src/assets/google-play-btn.svg");
const appStore = require("../../../src/assets/app-store-btn.svg");

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class HeaderApplication extends React.Component<{}, {}> {
    render() {
        return (
            <>
                <Helmet>
                    <title>Biotron Data App</title>
                </Helmet>
                <Navigation selected="app"/>
                <header className="mobile-app">
                    <div className="bg-mobile">
                        <div className="wrapper main-title">
                            <div className="left">
                                <h1 className="title bold white">Biotron Data App</h1>
                            </div>

                            <div className="right">
                                <div className="row-1">
                                    <span className="download-now">Download Now</span>
                                </div>
                                <div className="row-2">
                                    <a href="https://play.google.com/store/apps/details?id=io.biotron.app" target="_blank">
                                        <img className="google-play" src={googlePlay}/>
                                    </a>
                                    <img className="app-store" src={appStore}/>
                                </div>
                                <div className="row-3">
                                    <span className="coming-soon bg-white bold dark-blue">Coming soon</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <span className="clear"></span>
            </>
        );
    }
}
