import * as React from "react";

export interface ContentProps {
}

export interface ContentState {
    step: number;
    checkForm: CheckForm;
    userForm: UserForm;
    icoStatus: IcoStatus;
    payToWallet: string;
    formState: FormState;
    icoStatusState: IcoStatusState;
}

export enum FormState {
    WAITING = "WAITING",
    SENDING = "SENDING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export enum IcoStatusState {
    WAITING = "WAITING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

export interface CheckForm {
    check1?: boolean;
    check2?: boolean;
    check3?: boolean;
}

export interface UserForm {
    email: string;
    coin: string;
    wallet: string;
}

export interface IcoStatus {
    dateNow: number;
    endDate: number;
    humanRound: number;
    nextRoundTimeUTC: number;
    round: number;
    roundPrice: number;
    timeLeftTillNextRound: number;
    tokensForOneEth: number;
    tokensLeftThisRound: number;
    totalGeneratedTokens: number;
    totalLeftTokens: number;
    tokensForOne: any;
}

export class Blog extends React.Component<ContentProps, ContentState> {

    constructor(props: ContentProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <div className="conditions">
                <div className="container">
                    Blog route
                </div>
            </div>

        );
    }
}
