import * as React from "react";
import {HashRouter as Router, Route, Link, Switch} from "react-router-dom";
import {Application} from "./routes/application";
import {Btrn} from "./routes/btrn";
import {Foundation} from "./routes/foundation";
import {Blog} from "./routes/blog";
// import {Home} from "./routes/home";
import {Footer} from "./components/Footer";

// react router
// https://reacttraining.com/react-router/web/guides/quick-start

// popup
// https://hackernoon.com/introducing-reactjs-popup-modals-tooltips-and-menus-all-in-one-227de37766fa
class App extends React.Component {
    render() {
        return (
            <Router>
                <div className="App">
                    <Switch>
                        {/* <Route exact path="/" component={Home}/> */}
                        <Route exact path="/" component={Foundation}/>
                        <Route exact path="/app" component={Application}/>
                        <Route exact path="/blockchain" component={Btrn}/>
                        <Route exact path="/blog" component={Blog}/>
                        <Route component={Foundation}/>{/*page not found*/}
                    </Switch>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

export default App;
