import * as React from "react";

const buttonArrowRight = require("../../assets/buttons/button-arrow-right.svg");

export interface LetsTalkProps {
    isModalActive: boolean;
    setModalState(step: boolean);
}


export class LetsTalk extends React.Component<LetsTalkProps, {}> {

    constructor(props: LetsTalkProps) {
        super(props);
    }

    render(): JSX.Element {
        return (
            <>
                <div className="bg-blue">
                    <div className="wrapper lets-talk bg-blue">
                        <div className="block-left">
                            <h2 className="title bold">Do you want to know more?</h2>
                        </div>
                        <div className="block-right">
                            <div className="button button-dark-blue" onClick={() => this.props.setModalState(true)}>
                                <span className="text bold">Let's talk</span>
                                <span className="button-arrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
