import * as React from "react";
import "../index.css";
import { TeamProps } from "./common/modalForm";
import * as EmailValidator from "email-validator";
import CookieConsent, { Cookies } from "react-cookie-consent";

export interface FooterState {
    email: string;
    checked: boolean;
    sendStatus: string;
    isSend: boolean;
    errors: {
        email: boolean;
        emailNotValid: boolean;
        emptyEmail: boolean;
        emptyCheck: boolean;
    };
}

export class Footer extends React.Component<{}, FooterState> {

    constructor(props: TeamProps) {
        super(props);

        this.state = {
            email: "",
            checked: false,
            sendStatus: "",
            isSend: false,
            errors: {
                email: false,
                emailNotValid: false,
                emptyEmail: false,
                emptyCheck: false,
            }
        };
    }

    render() {
        const { email, errors, checked, sendStatus, isSend } = this.state;

        return (
            <>
                <footer>
                    <div className="wrapper footer-contact bg-footer">
                        <div className="block-left tablet-block-left">
                            <form className="subscribe-form" onSubmit={this._onSubmitForm}>

                                <h3 className="title">Get the latest news</h3>


                                {isSend &&
                                    <div className="input-box">
                                        <span className="send-subscribe">{sendStatus}</span>
                                    </div>
                                }

                                {!isSend && <>
                                    <div className="input-box">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Your email..."
                                            onChange={this.handleEmailChange}
                                            value={email}
                                            className={(errors.email) ? "error-text email-address" : "email-address"}
                                        />
                                        <button type="submit" className="submit white bold right">
                                            Subscribe
                                            <span className="button-arrow" />
                                        </button>
                                        {/*{errors.email &&*/}
                                        {/*<div className="alert alert-danger fade in alert-dismissible">*/}
                                        {/*<strong>Email is not valid</strong>*/}
                                        {/*</div>*/}
                                        {/*}*/}
                                    </div>
                                    <hr className="hr-line-footer" />
                                    <span className="checkbox left block">
                                        <label>
                                            <input type="checkbox" className="checkbox-style" value={(checked ? "checked" : "")}
                                                onChange={this.handleCheck} />
                                            <span className="custom-checkbox">
                                                <span className={(checked ? "selected" : "")} />
                                            </span>
                                            <span className={(errors.emptyCheck) ? "error-text terms" : "terms"}>I agree receiving marketing newsletter of Biotron in line with this
                                                 <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank"> Privacy Policy</a>
                                            </span>
                                        </label>
                                    </span>
                                </>
                                }

                            </form>
                        </div>
                        <div className="block-right tablet-block-right">
                            <div className="contact-info">
                                <div className="contact block-left">
                                    <p className="title">Contact</p>
                                    <p className="text">
                                        <a className="mail-to" href="mailto:hello@biotron.io">hello@biotron.io</a> <br />
                                    </p>
                                </div>
                                <div className="headquarters block-right">
                                    <p className="title">Headquarters</p>
                                    <p className="text">Stare Grunty 18 <br /> 841 04 Bratislava</p>
                                </div>
                            </div>
                            <span className="clear"></span>
                            <div className="social-links">
                                <a href="https://www.facebook.com/biotron.io/" target="_blank">
                                    <div className="item">
                                        <span className="facebook" />
                                    </div>
                                </a>
                                <a href="https://twitter.com/biotron_io" target="_blank">
                                    <div className="item">
                                        <span className="twitter" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper spacer"></div>
                    <div className="wrapper site-info bg-footer">
                        <div className="copyright">
                            <span>&copy; Copyright 2018-19 Biotron Foundation All Rights reserved.</span>
                        </div>
                        <div className="legals">
                            <a href="https://docs.biotron.io/Biotron_Terms_of_Use.pdf" target="_blank"><span className="terms">Terms of Use</span></a>
                            <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank"><span className="privacy">Privacy Policy</span></a>
                            <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank"><span className="cookies">Cookies</span></a>
                        </div>
                    </div>
                </footer>

                <CookieConsent
                    location="bottom"
                    buttonText="Yes, I agree."
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#3029d9" }}
                    buttonStyle={{
                        padding: "5px 8px",
                        minWidth: "100px",
                        marginLeft: "5px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        color: "rgb(255, 255, 255)",
                        background: "rgb(242, 145, 50)",
                        textAlign: "center"
                    }}
                    expires={150}>
                    We use cookies to enhance your experience. By continuing to visit this site you agree to
                    <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank"> our use of cookies.</a>
                    {/*<span style={{fontSize: "10px"}}>*/}
                    {/*This bit of text is smaller :O*/}
                    {/*</span>*/}
                </CookieConsent>
            </>
        );
    }

    private handleEmailChange = (e: any) => {
        const validEmail = EmailValidator.validate(e.target.value); // true
        const { errors, } = this.state;
        errors.emailNotValid = !validEmail;

        if (!errors.emailNotValid && e.target.value.toString().trim().length) {
            errors.email = false;
        }

        this.setState({
            email: e.target.value,
            errors: errors
        });
    }

    private handleCheck = (e: any) => {

        const { errors } = this.state;
        errors.emptyCheck = false;

        this.setState({
            checked: e.target.checked,
            errors: errors
        });
    }

    private _onSubmitForm = async (e: React.FormEvent<any>): Promise<void> => {
        e.preventDefault();
        const { email, errors, checked } = this.state;

        errors.emptyEmail = !email.toString().trim().length;
        errors.emptyCheck = !checked;

        if (errors.emptyEmail || errors.emailNotValid) {
            errors.email = true;
        } else {
            errors.email = false;
        }

        this.setState({
            errors: errors
        });



        let hasError;
        for (let p in errors) {
            if (errors[p]) {
                hasError = true;
            }
        }

        if (!hasError) {

            fetch("https://api.sendinblue.com/v3/contacts",
                {
                    method: "POST",
                    mode: "cors",
                    redirect: "follow", // *manual, error
                    referrer: "no-referrer", // *client
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *omit, same-origin
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "api-key": "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"
                    },
                    body: JSON.stringify(
                        { listIds: [15], email: email }
                    )
                })
                .then(response => {
                    // if (response.status >= 400) {
                    //     throw new Error("Bad response from server");
                    // }
                    return response.json();
                })
                .then(data => {

                    if (data.code === "duplicate_parameter") {
                        this.setState({
                            isSend: true,
                            sendStatus: "You are already registered on our mailing list. Your information has now been updated."
                        });
                    } else {
                        this.setState({
                            isSend: true,
                            sendStatus: "You have received an email. Please click on the confirmation link."
                        });
                    }
                })
                .catch(error => {
                    this.setState({
                        sendStatus: "You are already registered on our mailing list. Your information has now been updated."
                    });
                });
        }
    }
}
