import * as React from "react";
import {Helmet} from "react-helmet";
import {Route, Link, Switch} from "react-router-dom";
import {Navigation} from "../common/navigation";

// 'HelloProps' describes the shape of props.
// State is never set so we use the '{}' type.
export class HeaderFoundation extends React.Component<{}, {}> {
    render() {
        return (
            <>
                <Helmet>
                    <title>BIOTRON FOUNDATION</title>
                </Helmet>
                <Navigation selected="foundation" />
                <header className="biotron-token">
                    <div className="foundation">
                        <div className="main-title">
                        <h1 className="title bold white left">
                            Biotron Foundation
                        </h1>
                        </div>
                    </div>
                    <div className="wrapper developer-update">
                        <a className="bla" href="https://medium.com/biotron/biotron-announces-outlook-for-2021-and-revisits-last-year-5b17ca44db9a" target="_blank">
                            <span className="text update">Outlook for 2021</span>
                        </a>
                        <span className="text latest">Latest News</span>
                    </div>
                    <span className="clear"></span>
                    <div id="example"> </div>
                </header>
                <span className="clear"></span>
            </>
        );
    }
}
