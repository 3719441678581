import * as React from "react";
import * as EmailValidator from "email-validator";

const arrowSmallRightBlue = require("../../../src/assets/arrow-small-right-blue.svg");
export interface TeamProps {
    isActive?: boolean;

    setModalState(step: boolean);
}

export interface TeamState {
    name: string;
    email: string;
    message: string;
    checked: boolean;
    send: boolean;
    errors: {
        email: boolean;
        emailNotValid: boolean;
        emptyName: boolean;
        emptyEmail: boolean;
        emptyMessage: boolean;
        emptyCheck: boolean;
    };
}

export class ModalForm extends React.Component<TeamProps, TeamState> {

    constructor(props: TeamProps) {
        super(props);

        this.state = {
            name: "",
            email: "",
            message: "Hi Biotron! ",
            checked: false,
            send: false,
            errors: {
                email: false,
                emailNotValid: false,
                emptyEmail: false,
                emptyMessage: false,
                emptyName: false,
                emptyCheck: false,
            }
        };
    }

    render(): JSX.Element {

        const {isActive} = this.props;
        const {name, email, message, errors, checked, send} = this.state;

        return (
            <>
                {isActive && <>
                    <div id="modal">
                        <div id="modal-overlay">
                            <div id="modal-wrap">
                                <div id="modal-main">
                                    <span className="modal-close s" title="Zatvoriť" onClick={() => this.props.setModalState(false)}></span>

                                    {send &&
                                        <div className="bootstrap">
                                            <div className="alert alert-success">
                                                {/*<a href="#" className="close" data-dismiss="alert">×</a>*/}
                                                <strong>Success!</strong> Your message has been sent successfully.
                                            </div>
                                        </div>
                                    }

                                    {!send &&
                                    <form className="form" onSubmit={this._onSubmitForm}>
                                        <span className="block title bold dark-blue center">Get in Touch</span>

                                        <div className="title-block">


                                        </div>


                                        <div className="input-box left">
                                            <span className="input-title block name"> Name</span>
                                            <input type="text"
                                                   className={(errors.emptyName) ? "error-border username input-text" : "username input-text"}
                                                   value={name}
                                                   onChange={this.handleNameChange}
                                            />
                                            {/*{errors.emptyName &&*/}
                                            {/*<div className="alert alert-danger fade in alert-dismissible">*/}
                                                {/*<strong>Name is empty</strong>*/}
                                            {/*</div>*/}
                                            {/*}*/}

                                        </div>
                                        <div className="input-box left">
                                            <span className="input-title block email"> Email</span>
                                            <input
                                                type="text"
                                                name="email"
                                                onChange={this.handleEmailChange}
                                                value={email}
                                                className={(errors.email) ? "error-border email input-text" : "email input-text"}
                                            />
                                            {/*{errors.email &&*/}
                                            {/*<div className="alert alert-danger fade in alert-dismissible">*/}
                                                {/*<strong>Email is not valid</strong>*/}
                                            {/*</div>*/}
                                            {/*}*/}
                                        </div>

                                        <div className="input-box left">
                                            <span className="input-title block left"> Message</span>
                                            <textarea
                                                className={(errors.emptyMessage) ? "error-border message" : "message"}
                                                value={message}
                                                onChange={this.handleMessageChange}
                                            >
                                        </textarea>
                                        </div>

                                        <span className="checkbox left block">
                                            <label>
                                                <input type="checkbox" className="checkbox-style" value={(checked ? "checked" : "")} onChange={this.handleCheck} />
                                                <span className="custom-checkbox">
                                                    <span className={(checked ? "selected" : "")} />
                                                </span>
                                                <span className={(errors.emptyCheck) ? "error-text agree" : "agree"}>I agree with
                                                    <a href="https://docs.biotron.io/Biotron_Terms_of_Use.pdf" target="_blank">
                                                        <span className={(errors.emptyCheck) ? "error-text terms" : "terms"}> Terms of Use</span>
                                                    </a>
                                                </span>
                                            </label>
                                        </span>
                                        <div className="left acknowledge-privacy">I acknowledge that Biotron will process my personal data in according with this
                                        <span> </span>
                                            <a href="https://docs.biotron.io/Biotron_Privacy_Policy.pdf" target="_blank">
                                                <span>Privacy Policy</span>
                                            </a>
                                        </div>

                                        <button type="submit" className="submit white semi-bold right">
                                            Send
                                            <img src={arrowSmallRightBlue}/>
                                        </button>
                                        <span className="clear"></span>
                                    </form>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }


                {!isActive && <> </>}
            </>
        );
    }

    private handleNameChange = (e: any) => {

        const { errors } = this.state;
        errors.emptyName = false;

        this.setState({
            name: e.target.value,
            errors: errors
        });
    }

    private handleCheck = (e: any) => {

        const { errors } = this.state;
        errors.emptyCheck = false;

        this.setState({
            checked: e.target.checked,
            errors: errors
        });
    }

    private handleMessageChange = (e: any) => {

        const { errors } = this.state;
        errors.emptyMessage = false;

        this.setState({
            message: e.target.value,
            errors: errors
        });
    }

    private handleEmailChange = (e: any) => {
        const validEmail = EmailValidator.validate(e.target.value); // true
        const { errors, } = this.state;
        errors.emailNotValid = !validEmail;

        if (!errors.emailNotValid && e.target.value.toString().trim().length) {
            errors.email = false;
        }

        this.setState({
            email: e.target.value,
            errors: errors
        });
    }

    private closeModalDelay = (miliseconds: number) => {
        setTimeout(() => {
            this.setState({
                send: false
            });
            this.props.setModalState(false);
        }, miliseconds);
    }


    private _onSubmitForm = async (e: React.FormEvent<any>): Promise<void> => {

        e.preventDefault();
        const {name, email, message, errors, checked} = this.state;

        errors.emptyName = !name.toString().trim().length;
        errors.emptyEmail = !email.toString().trim().length;
        errors.emptyCheck = !checked;
        errors.emptyMessage = !message.toString().trim().length;

        if (errors.emptyEmail || errors.emailNotValid) {
            errors.email = true;
        } else {
            errors.email = false;
        }

        this.setState({
            send: false,
            errors: errors
        });

        let hasError;
        for (let p in errors) {
            if (errors[p]) {
                hasError = true;
            }
        }

        if (!hasError) {
            this.setState({
                send: true,
                name: "",
                email: "",
                message: "Hi Biotron!",
                checked: false
            });

            this.closeModalDelay(3000);

            fetch("https://api.biotron.io/identity/getintouch",
                {
                    method: "POST",
                    mode: "cors",
                    redirect: "follow", // *manual, error
                    referrer: "no-referrer", // *client
                    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: "same-origin", // include, *omit
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(
                        {email: email, name: name, text: message}
                    )
                })
                .then(response => {
                    // if (response.status >= 400) {
                    //     throw new Error("Bad response from server");
                    // }
                    // console.log(response);
                    return response.json();
                })
                .then(data => {

                    // console.log(data);
                    this.setState({
                        send: true,
                        name: "",
                        email: "",
                        message: "Hi Biotron!"
                    });
                })
                .catch(error => {
                    // this.setState({formState: FormState.ERROR});
                });
        }
    }

}
