import * as React from "react";
import {HeaderApplication} from "../components/application/HeaderApplication";

const dataIsValuable = require("../../src/assets/data-is-valuable.png");
const dataIsYours = require("../../src/assets/data-is-yours.svg");
const helpUsHelpYou = require("../../src/assets/help-us-help-you.svg");
const googlePlayDark = require("../../src/assets/google-play-btn-dark.svg");
const appStoreDark = require("../../src/assets/app-store-btn-dark.svg");

export interface ContentProps {
}

export class Application extends React.Component<ContentProps, {}> {

    constructor(props: ContentProps) {
        super(props);

        window.scrollTo(0, 0);
    }

    render(): JSX.Element {
        return (
            <>
                <HeaderApplication/>
                <div className="content application">
                    <div className="wrapper main-title bg-white">
                        <div className="title bold dark-blue">
                            Share your data, improve the world you live in
                        </div>
                    </div>

                    <div className="wrapper bg-white">
                        <div className="content-wrapper first dataIsValuable">
                            <div className="block-right">
                                <p className="title-semi">Your Data Is Valuable</p>
                            </div>
                            <div className="block-left">
                                <img src={dataIsValuable}/>
                            </div>
                            <div className="block-right">
                                <p className="description-semi">
                                    Data is the new currency. Wherever you look, companies use data to power innovation and
                                    improve their products.<br/><br/>
                                    To solve real-life problems, like traffic jams, or even life-threatening diseases, we
                                    need even more data to train AI algorithms and predict the future.<br/><br/>
                                    By sharing your data you can help to solve these problems.
                                </p>
                            </div>
                        </div>

                        <div className="content-wrapper dataIsYours">
                            <div className="block-left">
                                <p className="title-semi">Your Data Is Yours</p>
                            </div>
                            <div className="block-right">
                                <img className="right" src={dataIsYours}/>
                            </div>

                            <div className="block-left">
                                <p className="description-semi">
                                    You are in charge of what is collected and how it is used.<br/><br/>
                                    You can see all the transactions which happened - what data was used and how.
                                </p>
                            </div>
                        </div>

                        {/* <div className="content-wrapper helpUsHelpYou last">
                            <div className="block-right">
                                <p className="title-semi">Help Us Help You</p>
                            </div>
                            <div className="block-left">
                                <img src={helpUsHelpYou}/>
                            </div>
                            <div className="block-right">
                                <p className="description-semi">
                                    Using the BTRN Mobile App you can share your data and help us solve these problems.<br/><br/>
                                    You choose what you want to share and how we can use it. The more you share, the more
                                    you can earn. And at the same time you will help companies innovate and improve the
                                    world you live
                                    in.
                                </p>
                            </div>
                        </div> */}
                        <hr className="hr-line"/>
                    </div>

                    <div className="wrapper get-the-app bg-white">
                        <div className="left">
                            <span className="title bold dark-blue">Get the App</span>
                        </div>
                        <div className="right">
                            <div className="stores">
                                <a href="https://play.google.com/store/apps/details?id=io.biotron.app" target="_blank">
                                    <img className="google-play" src={googlePlayDark}/>
                                </a>
                                <img className="app-store" src={appStoreDark}/>
                            </div>
                            <div className="coming">
                                <span className="coming-soon">Coming soon</span>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
