import * as React from "react";
import {Route, Link, Switch} from "react-router-dom";
import {TeamProps} from "./modalForm";
import * as EmailValidator from "email-validator";

const biotronLogo = require("../../../src/assets/biotron_logo.svg");


export interface NavigationProps {
    selected: string;
}

interface NavigationState {
    backgroundType: string;
    backgroundTypeMobile: string;
    defaultMobileNav: string;
}

export class Navigation extends React.Component<NavigationProps, NavigationState> {

    constructor(props: NavigationProps) {
        super(props);

        this.state = {
            backgroundType: "basic",
            backgroundTypeMobile: "basic",
            defaultMobileNav: "topnav"
        };
    }

    componentDidMount() {
        document.addEventListener("scroll", () => {
            const scrollPosition = window.scrollY;

            let background = "basic";
            let backgroundMobile = "basic";
            if (scrollPosition > 90) {
                background = "navi-back-blue";
            }

            if (scrollPosition > 100) {
                backgroundMobile = "";
            }


            this.setState({backgroundType: background});
            this.setState({backgroundTypeMobile: backgroundMobile});
        });
    }

    render(): JSX.Element {
        const {selected} = this.props;
        const {backgroundType, backgroundTypeMobile, defaultMobileNav} = this.state;
        // selected semi-bold
        return (
            <>
                <div className={defaultMobileNav + " " + backgroundTypeMobile}>
                    <Link to="/" className="biotron-logo">
                        <img src={biotronLogo}/>
                    </Link>
                    <Link to="/blockchain">
                        <span className={selected === "btrn" ? "item selected semi-bold" : "item"}>Blockchain</span>
                    </Link>
                    <a href="https://biotron.io" target="_blank">
                        <span className="item">Labs</span>
                    </a>
                    <a href="https://medium.com/biotron" target="_blank">
                        <span className="item">News</span>
                    </a>

                    <a href="" className="icon" onClick={this.myFunction}>&#9776;</a>
                </div>

                <div className={backgroundType + " navibar"}>
                    <div className="wrapper navigation">
                        <div className="logo" onClick={() => window.scrollTo(0, 0)}>
                            <Link to="/"><img src={biotronLogo}/></Link>
                        </div>
                        <div className="nav-bar">
                            <Link to="/blockchain">
                                <span className={selected === "btrn" ? "item selected semi-bold" : "item"}>Blockchain</span>
                            </Link>
                            <a href="https://biotron.io" target="_blank">
                                <span className="item">Labs</span>
                            </a>
                            <a href="https://medium.com/biotron" target="_blank">
                                <span className="item">News</span>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    /* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */
    private myFunction = (e: React.FormEvent<any>) => {
        e.preventDefault();

        const {defaultMobileNav} = this.state;
        if (defaultMobileNav === "topnav") {
            this.setState({
                defaultMobileNav: defaultMobileNav + " responsive"
            });
        } else {
            this.setState({
                defaultMobileNav: "topnav"
            });
        }
    }

}
