import * as React from "react";
import {HeaderFoundation} from "../components/foundation/HeaderFoundation";
import {LetsTalk} from "../components/common/letsTalk";
import {FeaturedIn} from "../components/common/featuredIn";
import {Team} from "../components/common/team";
import {ModalForm} from "../components/common/modalForm";

export interface ContentProps {
}

export interface FoundationState {
    isModalActive: boolean;
}

export class Foundation extends React.Component<ContentProps, FoundationState> {

    constructor(props: ContentProps) {
        super(props);

        window.scrollTo(0, 0);

        this.state = {
            isModalActive: false
        };
    }

    render(): JSX.Element {
        return (
            <>
                <HeaderFoundation/>
                <div className="content foundation">
                    <div className="wrapper bg-white">
                        <div className="title bold dark-blue">
                            We make data available in a transparent and privacy- compliant way to power innovation
                            worldwide.
                        </div>
                        <div className="quote">
                            <span className="text">
                                “Privacy means people know what they’re signing up for, in plain English, and repeatedly.
                            I’m an optimist; I believe people are smart, and some people want to share more data than other
                            people do. Ask them. Ask them every time. Make them tell you to stop asking them if they get tired
                            of your asking them. Let them know precisely what you’re going to do with their data.”
                            </span>
                            <span className="author">
                               - Steve Jobs, 2009
                            </span>
                        </div>

                        <div className="vision">
                            <div className="block-left">
                                <p className="description">
                                    Biotron’s vision is to make data available in a transparent and privacy-compliant way.
                                    That’s what we do and will never change. We know that to increase their efficiency,
                                    businesses need access to high-quality datasets. Whether they’re in retail, transport,
                                    or healthcare industry. But when data is produced by users, they should be properly
                                    rewarded—directly, indirectly, or both—with transparency and privacy at the core of the
                                    process.
                                    That’s not always the case and we strongly believe it should change.
                                </p>
                            </div>
                            <div className="block-right">
                                <p className="description">
                                    BIOTRON FOUNDATION is built around these very principles.
                                    Our goal is to provide companies with technical and legal frameworks that will help them
                                    stick to these principles, while having access to high-quality datasets at the same
                                    time.
                                    On the other hand, users will be rewarded for sharing them.
                                </p>
                            </div>
                        </div>
                        <hr className="hr-line"/>
                    </div>
                    <span className="clear"></span>

                    <FeaturedIn/>
                </div>
                {/* <Team/> */}

                <LetsTalk setModalState={this.setModalState} isModalActive={this.state.isModalActive} />
                <ModalForm isActive={this.state.isModalActive} setModalState={this.setModalState}/>
            </>
        );
    }

    private setModalState = (modalState: boolean) => {

        let isActive = !this.state.isModalActive;
        if (!modalState) {
            isActive = false;
        }

        this.setState({isModalActive: isActive});
    }
}
